<template>
  <div>
    <mailchimp-action-assign
      :is-assign-mail-action-sidebar-active.sync="
        isAssignMailActionSidebarActive
      "
      :action-data="currentDataAction"
      @refetch-data="refreshTable"
    />
    <mailchimp-action-test
      :is-test-mailchimp-action-sidebar-active.sync="
        isTestMailchimpActionSidebarActive
      "
      :action-id="currentActionId"
      @refetch-data="refreshTable"
    />
    <mailchimp-action-update
      :is-update-mail-action-side-bar-active.sync="
        isUpdateMailActionSidebarActive
      "
      :mail-action-data="currentDataAction"
      @refetch-data="refreshTable"
    />
    <!-- Filters -->
    <region-filters
      :region-options="regionOptions"
      :region-filter.sync="regionFilter"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refMailActionTable"
          class="position-relative"
          :items="fetchMailActions"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-link v-if="$can('mail_update')" @click="showModal(data.item)">
              Assign
            </b-link>
            <b-link @click="excute(data.item)"> Test </b-link>
            <b-link v-if="$can('mail_update')" @click="update(data.item)">
              Edit
            </b-link>
            <b-link
              v-if="$can('tracking_read')"
              @click="showModalTracking(data.item.id)"
            >
              Tracking
            </b-link>
          </template>
          <!-- Column: CreatedAt -->
          <template #cell(createdAt)="data">
            <span class="text-capitalize">
              {{ formatDate(data.item.createdAt) }}
            </span>
          </template>
          <!-- Column: regionId -->
          <template #cell(regionId)="data">
            <span class="text-capitalize">
              {{ data.item.regionId.toUpperCase() }}
            </span>
          </template>
          <!-- Column: sendToAdmin -->
          <template #cell(sendToAdmin)="data">
            <b-badge
              pill
              :variant="data.item.sendToAdmin ? 'success' : 'danger'"
              class="text-capitalize"
            >
              {{ data.item.sendToAdmin }}
            </b-badge>
          </template>
          <!-- Column: sendToUser -->
          <template #cell(sendToUser)="data">
            <b-badge
              pill
              :variant="data.item.sendToUser ? 'success' : 'danger'"
              class="text-capitalize"
            >
              {{ data.item.sendToUser }}
            </b-badge>
          </template>
          <!-- Column: input -->
          <template #cell(input)="data">
            <span class="text-capitalize">
              <!-- {{ trimString(data.item.input, 10) }} -->
              {{ data.item.input ? trimString(data.item.input, 10) : "" }}
            </span>
          </template>

          <!-- Column: Template Usage -->
          <template #cell(template)="data">
            <span class="text-capitalize">
              {{
                data.item.template
                  ? `[${data.item.template.regionId.toUpperCase()}] ${
                      data.item.template.name
                    }`
                  : "No template selected"
              }}
            </span>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalActions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <tracking-status
      :is-show-modal-tracking.sync="isShowModalTracking"
      :target="target"
      :actor="actor"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import store from "@/store";
import vSelect from "vue-select";
// eslint-disable-next-line import/no-cycle
import { ref } from "@vue/composition-api";
// eslint-disable-next-line import/no-cycle
import { avatarText } from "@core/utils/filter";
// eslint-disable-next-line import/no-cycle

import MailchimpActionAssign from "@/components/mailchimp/action/mailchimpActionAssign.vue";
import mailchimpActionTest from "@/components/mailchimp/action/mailchimpActionTest.vue";
import mailchimpActionUpdate from "@/components/mailchimp/action/mailchimpActionUpdate.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// eslint-disable-next-line import/no-cycle
import { mapGetters } from "vuex";
import { trimString, formatDate } from "../../../utils/utils";
import useMailAction from "./useMailAction";
import TrackingStatus from "../../modal/TrackingStatus.vue";
import RegionFilters from "@/components/mailchimp/action/RegionFilters.vue";

export default {
  components: {
    BOverlay,
    TrackingStatus,
    mailchimpActionUpdate,
    mailchimpActionTest,
    MailchimpActionAssign,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    vSelect,
    BBadge,
    RegionFilters,
  },
  setup() {
    // Use toast
    const isShowModalTracking = ref(false);
    const target = ref("");
    const actor = ref("");
    const toast = useToast();
    const isAssignMailActionSidebarActive = ref(false);
    const isTestMailchimpActionSidebarActive = ref(false);
    const isUpdateMailActionSidebarActive = ref(false);
    const currentActionId = ref(0);
    const {
      timeout,
      showTable,
      fetchMailActions,
      tableColumns,
      perPage,
      currentPage,
      totalActions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailActionTable,
      refetchData,
      regionFilter,
    } = useMailAction();
    return {
      timeout,
      showTable,
      isShowModalTracking,
      actor,
      target,
      currentActionId,
      toast,
      formatDate,
      // Sidebar
      isUpdateMailActionSidebarActive,
      isTestMailchimpActionSidebarActive,
      isAssignMailActionSidebarActive,
      fetchMailActions,
      tableColumns,
      perPage,
      currentPage,
      totalActions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMailActionTable,
      refetchData,
      trimString,
      regionFilter,
    };
  },
  data() {
    return {
      currentDataAction: {},
      modalShow: false,
      newRole: null,
      nameState: null,
    };
  },
  computed: {
    ...mapGetters("app", ["regionOptions"]),
  },
  methods: {
    refreshTable() {
      this.showTable = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.refetchData();
        this.showTable = false;
      }, 600);
    },
    showModalTracking(id) {
      this.target = `storeConnection:mailAction:${id}`;
      this.actor = "";
      this.isShowModalTracking = true;
    },
    update(data) {
      this.currentDataAction = data;
      this.isUpdateMailActionSidebarActive = true;
    },
    excute(data) {
      this.isTestMailchimpActionSidebarActive = true;
      this.currentActionId = data.id;
    },
    showModal(data) {
      this.isAssignMailActionSidebarActive = true;
      this.currentDataAction = data;
    },
    deleteUser(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this user.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            store.dispatch("user/deleteUser", { id }).then(() => {
              this.refreshTable();
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
