<template>
  <b-sidebar
    id="add-update-mailchimp-account-sidebar"
    :visible="isUpdateMailActionSideBarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-update-mail-action-side-bar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Update mailchimp account
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--  subjectTitle -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="mailActionData.subjectTitle"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="PwC Marketplace MY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--  emailSend -->
          <validation-provider
            #default="validationContext"
            name="Email send"
            rules="required"
          >
            <b-form-group
              label="Email send"
              label-for="emailSend"
            >
              <b-form-input
                id="emailSend"
                v-model="mailActionData.emailSend"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="PwC Marketplace MY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!--  emailTo -->
          <b-form-group
            label="Email to"
            label-for="emailTo"
          >
            <b-form-input
              id="emailTo"
              v-model="mailActionData.emailTo"
              autofocus
              trim
              placeholder="pwc@mail.com"
            />

          </b-form-group>
          <!--  emailBcc -->
          <b-form-group
            label="Email Bcc"
            label-for="emailBcc"
          >
            <b-form-input
              id="emailBcc"
              v-model="mailActionData.emailBcc"
              autofocus
              trim
              placeholder="pwc@mail.com"
            />

            <!--  sendToAdmin -->
            <b-form-group
              label="Send to admin"
              label-for="sendToAdmin"
            >
              <v-select
                v-model="mailActionData.sendToAdmin"
                :options="selectOptions"
                :reduce="val => val.key"
                :clearable="false"
              />
            </b-form-group>
          </b-form-group>

          <!--  sendToUser -->
          <b-form-group
            label="Send to user"
            label-for="sendToUser"
          >
            <v-select
              v-model="mailActionData.sendToUser"
              :options="selectOptions"
              :reduce="val => val.key"
              :clearable="false"
            />

          </b-form-group>
          <!--  emailName -->
          <validation-provider
            #default="validationContext"
            name="Email name"
            rules="required"
          >
            <b-form-group
              label="Email name"
              label-for="emailName"
            >
              <b-form-input
                id="emailName"
                v-model="mailActionData.emailName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="PwC Marketplace MY"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUpdateMailActionSideBarActive',
    event: 'update:is-update-mail-action-side-bar-active',
  },
  props: {
    isUpdateMailActionSideBarActive: {
      type: Boolean,
      required: true,
    },
    mailActionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      selectOptions: [{
        label: 'True',
        key: true,
      },
      {
        label: 'False',
        key: false,
      }],
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const blankMailchimpAccount = {
      name: '',
      key: '',
      regionId: '',
    }
    const mailchimpAccount = ref(JSON.parse(JSON.stringify(blankMailchimpAccount)))
    const resetAccountData = () => {
      mailchimpAccount.value = JSON.parse(JSON.stringify(blankMailchimpAccount))
    }
    const onSubmit = () => {
      const tranferData = {
        subjectTitle: props.mailActionData.subjectTitle,
        emailSend: props.mailActionData.emailSend,
        emailName: props.mailActionData.emailName,
        emailTo: props.mailActionData.emailTo,
        emailBcc: props.mailActionData.emailBcc,
        sendToAdmin: props.mailActionData.sendToAdmin,
        sendToUser: props.mailActionData.sendToUser,
      }
      store
        .dispatch('mailchimp/updateMailActionsTemplate', {
          action_id: props.mailActionData.id,
          action: tranferData,
        })
        .then(() => {
          emit('update:is-update-mail-action-side-bar-active', false)
          emit('refetch-data')
        })
        .catch(() => {
          emit('update:is-update-mail-action-side-bar-active', false)
          emit('refetch-data')
        })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetAccountData,
    )

    return {
      toast,
      mailchimpAccount,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-update-mailchimp-account-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
