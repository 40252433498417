export const languages = {
  en: "English",
  ja: "Japanese",
  ko: "Korean",
  vi: "Vietnamese",
  id: "Indonesian",
  ms: "Malay",
  zh: "Chinese",
  th: "Thai",
};
