import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { languages } from "@/utils/constants";

export default function useMailAction() {
  // Use toast
  const toast = useToast();
  const showTable = ref(false);
  const refMailActionTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: false, class: "text-nowrap" },
    {
      key: "regionId",
      label: "Region",
      formatter: title,
      sortable: false,
    },
    {
      key: "language",
      label: "Language",
      formatter: (value) => languages[value],
      sortable: false,
    },
    { key: "subjectTitle", sortable: false, class: "text-nowrap" },
    { key: "emailName", sortable: false },
    { key: "emailSend", sortable: false, class: "text-nowrap" },
    { key: "emailTo", sortable: false },
    { key: "emailBcc", sortable: false },
    { key: "sendToAdmin", sortable: false },
    { key: "sendToUser", sortable: false },
    {
      key: "input",
    },
    { key: "template" },
    { key: "createdAt", sortable: false },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalActions = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const regionFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refMailActionTable.value
      ? refMailActionTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalActions.value,
    };
  });

  const refetchData = () => {
    refMailActionTable.value.refresh();
  };

  const fetchMailActions = (ctx, callback) => {
    store
      .dispatch("mailchimp/fetchMailActions", {
        q: searchQuery.value,
        take: perPage.value,
        page: currentPage.value,
        region: regionFilter.value,
      })
      .then((response) => {
        const { data: actions, meta } = response.body;
        callback(actions);
        totalActions.value = meta.itemCount;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const timeout = ref(null);
  watch([currentPage, perPage, searchQuery, regionFilter], () => {
    showTable.value = true;
    clearTimeout(timeout.value);

    timeout.value = setTimeout(() => {
      refetchData();
      showTable.value = false;
    }, 2000);
  });
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) =>
    // TODO change to is Active
    // if (status === 'active') return 'success'
    // if (status === 'inactive') return 'secondary'
    "success";

  return {
    timeout,
    showTable,
    fetchMailActions,
    tableColumns,
    perPage,
    currentPage,
    totalActions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMailActionTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
    regionFilter,
  };
}
