<template>
  <b-sidebar id="update-mail-action" :visible="isAssignMailActionSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-assign-mail-action-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Assign mail action
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->

      <!-- Form -->
      <b-form class="p-2">
        <b-form-group label="Template usage" label-for="action-template">
          <vue-autosuggest v-model="query" :suggestions="suggestions" :input-props="inputProps"
            :section-configs="sectionConfigs" :render-suggestion="renderSuggestion" :limit="10" @input="fetchResults" />

        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="onSubmit">
            Assign
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BCardText,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCardText,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    VueAutosuggest,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAssignMailActionSidebarActive',
    event: 'update:is-assign-mail-action-sidebar-active',
  },
  props: {
    isAssignMailActionSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      datasuggest: [],
      query: '',
      suggestions: [],
      selected: null,
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Template',
      },
      sectionConfigs: {
        developers: {
          limit: 6,
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        pages: {
          limit: 6,
          label: 'Pages',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
      limit: 10,
    }
  },
  created() {
    this.fetchMailchimpTemplates()
  },
  methods: {
    fetchMailchimpTemplates() {
      store
        .dispatch('mailchimp/fetchMailChimpTemplates', {
          q: this.query,
          take: 10,
          page: 1,
        })
        .then(response => {
          const { data } = response.body
          this.datasuggest = data
        })
    },
    fetchResults(text) {
      const { query } = this
      if (query) {
        this.suggestions = []
        this.fetchMailchimpTemplates()
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredDevelopers = this.datasuggest.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      filteredDevelopers.length && this.suggestions.push({ name: 'developers', data: filteredDevelopers })
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'developers') {
        const dev = suggestion.item
        return (
          <div class="d-flex align-items-center">
            <div class="detail">
              <b-card-text class="mb-0">{dev.name}</b-card-text>
            </div>
          </div>
        )
      }
      return false
    },
    onSubmit() {
      store
        .dispatch('mailchimp/assignMailActionsTemplate', {
          action_id: this.actionData.id,
          template_id: this.selected.id,
        })
        .then(() => {
          this.$emit('refetch-data')
          this.$emit('update:is-assign-mail-action-sidebar-active', false)
        })
        .catch(e => {
          this.$emit('refetch-data')
          this.$emit('update:is-assign-mail-action-sidebar-active', false)
        })
    },
  },
  setup(props, { emit }) {
    const selectedTemplate = ref(null)
    const filteredOptions = ref([])
    const toast = useToast()
    const blankNewTemplate = {
      templateId: '',
    }
    const templateSelected = ref(JSON.parse(JSON.stringify(blankNewTemplate)))
    const resetuserData = () => {
      templateSelected.value = ref(JSON.parse(JSON.stringify(blankNewTemplate)))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )

    return {
      selectedTemplate,
      filteredOptions,
      toast,
      templateSelected,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";

#update-mail-action {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
